import Image from "next/image"
import { claroBrand } from "../../assets/footer"
import useGeneralRules from "../../hooks/useGeneralRules"
import CroService from "../../services/CroService"
import styles from "./styles.module.scss"

interface ComponentProps {
  storyBlok?: unknown
}

export default function Footer(props: ComponentProps) {
  const currentYear = new Date().getFullYear()
  const { storyblok } = useGeneralRules()
  const isBlackFriday = storyblok?.blackFriday?.activated

  const goToLink = (link: string) => {
    window.open(link)
  }

  const dataLayerSocialNetwork = (socialNetwork: string) => {
    CroService.postDataLayer({
      event: "event",
      eventCategory: "planos-claro-res:geral",
      eventAction: "clique:link",
      eventLabel: "icone:" + socialNetwork
    })
  }

  const dataLayerDisclaimerLink = (disclaimerLink: string) => {
    disclaimerLink = camelToHyphen(disclaimerLink)
    CroService.postDataLayer({
      event: "event",
      eventCategory: "planos-claro-res:geral",
      eventAction: "clique:link",
      eventLabel: "link:" + disclaimerLink
    })
  }

  const camelToHyphen = (input) => {
    return input.replace(/([a-z])([A-Z])/g, "$1-$2").toLowerCase()
  }

  const claroSocialLinks = {
    facebook: "https://facebook.com/clarobrasil/",
    twitter: "https://twitter.com/ClaroBrasil",
    instagram: "https://www.instagram.com/clarobrasil/?hl=pt-br",
    youtube: "https://www.youtube.com/clarobrasil",
    linkedin: "https://br.linkedin.com/company/clarobrasil",
    tiktok: "https://www.tiktok.com/@clarobrasil"
  }

  const claroDisclaimerLinks = {
    politicaDePrivacidade:
      "https://www.claro.com.br/privacidade/politica-de-privacidade",
    portalDePrivacidade: "https://www.claro.com.br/privacidade"
  }

  const handleClickOnSocialNetwork = (platform: string) => {
    goToLink(claroSocialLinks[platform])
    dataLayerSocialNetwork(platform)
  }

  const handleClickOnDisclaimerLink = (platform: string) => {
    goToLink(claroDisclaimerLinks[platform])
    dataLayerDisclaimerLink(platform)
  }

  const showHandTalk = () => {
    const accessibilityLibrasButton: HTMLElement = document.querySelectorAll(
      'button[icon="accessibility-Libras"]'
    )[0] as HTMLElement
    if (accessibilityLibrasButton) {
      accessibilityLibrasButton.click()
    }

    CroService.postDataLayer({
      event: "event",
      eventCategory: "planos-claro-res:geral",
      eventAction: "clique:link",
      eventLabel: "footer:libras"
    })
  }

  return (
    <div className={isBlackFriday ? "black-friday" : ""}>
      <footer
        className="mdn-Footer mdn-Footer--social mdn-Footer--signature"
        id="main-footer"
      >
        <div className="mdn-Container">
          <div className="mdn-Footer-content">
            <div className="mdn-Footer-social-and-disclaimer">
              <ul className="mdn-Footer-list">
                <li className="mdn-Footer-list-item">
                  <a
                    className="mdn-Footer-list-item-socialLink"
                    href=""
                    onClick={() => handleClickOnSocialNetwork("facebook")}
                    target="_blank"
                    aria-label="Facebook"
                  >
                    <i
                      aria-hidden="true"
                      aria-label="Facebook"
                      className="mdn-Icon-facebook mdn-Icon--md"
                    ></i>
                  </a>
                </li>
                <li className="mdn-Footer-list-item">
                  <a
                    className="mdn-Footer-list-item-socialLink"
                    href=""
                    onClick={() => handleClickOnSocialNetwork("twitter")}
                    target="_blank"
                    aria-label="Twitter"
                  >
                    <i
                      aria-hidden="true"
                      aria-label="twitter"
                      className="mdn-Icon-twitter mdn-Icon--md"
                    ></i>
                  </a>
                </li>
                <li className="mdn-Footer-list-item">
                  <a
                    className="mdn-Footer-list-item-socialLink"
                    href=""
                    onClick={() => handleClickOnSocialNetwork("instagram")}
                    target="_blank"
                    aria-label="Instagram"
                  >
                    <i
                      aria-hidden="true"
                      aria-label="instagram"
                      className="mdn-Icon-instagram mdn-Icon--md"
                    ></i>
                  </a>
                </li>
                <li className="mdn-Footer-list-item">
                  <a
                    className="mdn-Footer-list-item-socialLink"
                    href=""
                    onClick={() => handleClickOnSocialNetwork("youtube")}
                    target="_blank"
                    aria-label="Youtube"
                  >
                    <i
                      aria-hidden="true"
                      aria-label="youtube"
                      className="mdn-Icon-youtube mdn-Icon--md"
                    ></i>
                  </a>
                </li>
                <li className="mdn-Footer-list-item">
                  <a
                    className="mdn-Footer-list-item-socialLink"
                    href=""
                    onClick={() => handleClickOnSocialNetwork("linkedin")}
                    target="_blank"
                    aria-label="Linkedin"
                  >
                    <i
                      aria-hidden="true"
                      aria-label="linkedin"
                      className="mdn-Icon-linkedin mdn-Icon--md"
                    ></i>
                  </a>
                </li>
                <li className="mdn-Footer-list-item">
                  <a
                    className="mdn-Footer-list-item-socialLink"
                    href=""
                    onClick={() => handleClickOnSocialNetwork("tiktok")}
                    target="_blank"
                    aria-label="Tik Tok"
                  >
                    <i
                      aria-hidden="true"
                      aria-label="tik tok"
                      className="mdn-Icon-tik-tok mdn-Icon--md"
                    ></i>
                  </a>
                </li>
              </ul>

              <div className="mdn-Footer-disclaimer">
                <p className="mdn-Text mdn-Text--caption mdn-Text--inverse">
                  <a
                    className={`mdn-Footer-disclaimer-link ${styles.disclaimerLink}`}
                    href=""
                    onClick={() =>
                      handleClickOnDisclaimerLink("politicaDePrivacidade")
                    }
                    target="_blank"
                    aria-label="Política de Privacidade"
                  >
                    Política de Privacidade
                  </a>
                  <a
                    className={`mdn-Footer-disclaimer-link ${styles.disclaimerLink}`}
                    href=""
                    onClick={() =>
                      handleClickOnDisclaimerLink("portalDePrivacidade")
                    }
                    target="_blank"
                    aria-label="Portal de Privacidade"
                  >
                    Portal de Privacidade
                  </a>
                  <a
                    className={`mdn-Footer-disclaimer-librasLink ${styles.disclaimerLink}`}
                    href=""
                    onClick={() => showHandTalk()}
                    aria-label="Portal de Libras"
                  >
                    <span className="mdn-Icon-libras mdn-Icon--sm"></span>
                  </a>
                </p>
                <p className="mdn-Text mdn-Text--caption mdn-Text--inverse">
                  <strong>
                    © {currentYear} Claro - CNPJ: 40.432.544/0001- 47 <br />
                    Rua Henri Dunant, 780 - São Paulo - SP
                  </strong>
                </p>
              </div>
            </div>

            <div className={styles.footerContent}>
              <Image
                src={claroBrand.src}
                alt="Logo Claro"
                width={48}
                height={48}
              />
            </div>
          </div>
        </div>
      </footer>
    </div>
  )
}
