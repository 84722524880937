/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useEffect, useRef, useState } from "react";
import useGeneralRules from "../../hooks/useGeneralRules";
import useTheme from "../../hooks/useTheme";
import CroService from "../../services/CroService";
import Modal from "../EcomModal";
import Image from "next/image";

interface ComponentProps {
  storyBlok?: unknown;
}

export default function EcomMenu(props: ComponentProps) {
  const { toggleTheme } = useTheme()
  const modalJaSouClienteRef = useRef(null)
  const modalAjustarFonteRef = useRef(null)
  const [isSkipLinkShown, setIsSkipLinkShown] = useState(false)
  const firstTabLinkRef = useRef(null);
  const { storyblok } = useGeneralRules()
  const isBlackFriday = storyblok?.blackFriday?.activated

  const downHandler = useCallback(({ key }) => {
    function isFullyVisible(element) {
      const rect = element.getBoundingClientRect();
      const windowHeight = window.innerHeight;
      return rect.top >= 0 && rect.bottom <= windowHeight;
    }

    if (key === "Tab") {
      window.removeEventListener("keydown", downHandler)
      setIsSkipLinkShown(true)

      if(firstTabLinkRef.current && !isFullyVisible(firstTabLinkRef.current)) {
        firstTabLinkRef.current.scrollIntoView({ block: 'start', behavior: 'smooth' });
      }
      firstTabLinkRef.current.focus();
    }
  }, [])

  useEffect(() => {
    window.addEventListener("keydown", downHandler)
    return () => {
      window.removeEventListener("keydown", downHandler)
    }
  }, [downHandler])

  function showHandTalk() {
    const accessibilityLibrasButton: HTMLElement = document.querySelectorAll('button[icon="accessibility-Libras"]')[0] as HTMLElement
    if(accessibilityLibrasButton){
      accessibilityLibrasButton.click()
    }

    CroService.postDataLayer({
      event: 'event',
      eventCategory: "planos-claro-res:geral",
      eventAction: "clique:link",
      eventLabel: "menu:libras"
    })
  }

  function handleClickOnLogo(): void {
    CroService.postDataLayer({
      event: 'event',
      eventCategory: "planos-claro-res:geral",
      eventAction: "clique:link",
      eventLabel: "logo-claro"
    })

    window.location.href = "https://www.claro.com.br/";
  }

  function handleClickOnAcessibilidade(): void {
    CroService.postDataLayer({
      event: 'event',
      eventCategory: "planos-claro-res:geral",
      eventAction: "clique:link",
      eventLabel: "acessibilidade"
    })
  }

  function handleToggleTheme(): void {
    toggleTheme()
    CroService.postDataLayer({
      event: 'event',
      eventCategory: "planos-claro-res:geral",
      eventAction: "clique:link",
      eventLabel: "contraste"
    })
  }

  function handleOpenModal(): void {
    modalAjustarFonteRef.current.handleModalVisibility();
    CroService.postDataLayer({
      event: 'event',
      eventCategory: "planos-claro-res:modal-acessibilidade-foco",
      eventAction: "modal:abriu",
      eventLabel: "acessibilidade-foco"
    })
  }

  return (
    <>
    <div className={isBlackFriday ? 'black-friday': ''}>
      <header className="mdn-Menu mdn-Menu--public" id="mdn-Menu" role="banner">
        {isSkipLinkShown && (
          <div id="skip-link" className="skip-link defaultColor">
            <div className="mdn-Container">
              <ul className="list-container">
                <li ref={firstTabLinkRef} >
                  <a
                    className="mdn-LinkList-anchor is-skip-link gtm-link-event"
                    href="#main-content"
                    onClick={() => (location.hash = "main-content")}
                    tabIndex={0}
                  >
                    Ir para o corpo do site
                  </a>
                </li>
                <li>
                  <a
                    className="mdn-LinkList-anchor is-skip-link gtm-link-event"
                    href="#main-header"
                    onClick={() => (location.hash = "main-header")}
                    tabIndex={0}
                  >
                    Ir para o Cabeçalho
                  </a>
                </li>
                <li>
                  <a
                    className="mdn-LinkList-anchor is-skip-link gtm-link-event"
                    href="#main-footer"
                    tabIndex={0}
                  >
                    Ir para o Rodapé
                  </a>
                </li>
              </ul>
            </div>
          </div>
        )}

        <div className="mdn-Menu-top" id="main-header">
          <div className="mdn-Container">
            <nav
              className="mdn-Menu-top-content"
              aria-label="Menu-top-content"
            >
              <ul className="mdn-Menu-top-list mdn-Menu-top-list-right">
                <li className="mdn-Menu-top-list-item mdn-Menu-top-list-item-accessibility accessibilityItem">
                  <a
                    className="mdn-Menu-top-event mdn-Text gtm-element-event"
                    onClick={handleClickOnAcessibilidade}
                    title="Conheça as iniciativas de Acessibilidade da Claro"
                    href="https://claro.com.br/institucional/regulatorio/acessibilidade?_gl=1*1tnuvw*_ga*OTMyNjkwMjcyLjE2MDIwODUwMjk.*_ga_XP4FQ2JR2D*MTYzMDU5NDc4NC4zMy4xLjE2MzA1OTQ3ODUuNTk."
                    target="_blank" rel="noreferrer"
                  >
                    <span className="mdn-Icon-pcd mdn-Icon--sm" aria-hidden="true"></span>
                  </a>
                </li>

                <li className="mdn-Menu-top-list-item mdn-Menu-top-list-item-accessibility">
                  <button className="mdn-Menu-top-event"
                    onClick={handleToggleTheme}
                    title="Opção de acessibilidade. Clique para alterar o contraste do Portal">
                    <span className="mdn-Icon-alto-contraste mdn-Icon--sm" aria-hidden="true"></span>
                  </button>
                </li>

                <li className="mdn-Menu-top-list-item mdn-Menu-top-list-item-accessibility">
                  <button type="button" className="mdn-Menu-top-event"
                    onClick={showHandTalk}
                    title="Assistente de Libras">
                    <span className="mdn-Icon-libras mdn-Icon--sm" aria-hidden="true"></span>
                  </button>
                </li>

                <li className="mdn-Menu-top-list-item mdn-Menu-top-list-item-accessibility">
                  <button className="mdn-Menu-top-event mdn-Modal--handler"
                          title="Modal Zoom texto"
                          onClick={handleOpenModal}>
                    <span className="mdn-Icon-diminuir-tamanho mdn-Icon--sm  mdn-Menu-text-icon" aria-hidden="true"></span>
                    <span className="mdn-Icon-aumentar-tamanho mdn-Icon--sm  mdn-Menu-text-icon" aria-hidden="true"></span>
                  </button>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div className="mdn-Menu-container mdn-Container">
          <nav className="mdn-Menu-content">
            <div className="mdn-Menu-content-left">
              <Image
                className="mdn-Menu-logo"
                src="https://mondrian.claro.com.br/brands/horizontal/inverse/claro.svg"
                alt="Logo da Claro"
                onClick={handleClickOnLogo}
                width={75}
                height={32}
              />
            </div>
          </nav>
        </div>
      </header>
    </div>

      <Modal
        ref={modalJaSouClienteRef}
        header="Já sou cliente"
        body="Você será redirecionado para Já sou cliente"
        buttonType="link"
        buttonLabel="Ok"
        link={
          "https://minhanet.net.com.br/mudeseuplano/token?utm_source=redirect&utm_medium=token&utm_campaign=porteira_cep_planos"
        }
      />

      <Modal
        ref={modalAjustarFonteRef}
        header="Ajuste de Fontes"
        body="Para aumentar ou reduzir a fonte, utilize os atalhos nativos do seu navegador. No windows Control+ (para aumentar) e Control- (para diminuir). No macOS (safari) cmd+ (para aumentar) e cmd- (para diminuir)"
        buttonType="button"
        buttonLabel="Ok"
      />
    </>
  )
}
