import { useContext, useEffect } from "react";
import { setTheme } from "mondrian-react";
import { ThemeContext } from "../contexts/theme";
import CroService from "../services/CroService";

const useTheme = () => {
  const { themeState, setThemeState } = useContext(ThemeContext)

  useEffect(() => {
    const setInitialTheme = () => {
      const theme = window.localStorage.getItem("theme")
      let currentTheme = ""
      if (!theme || theme === "claro") {
        currentTheme = "claro"
      } else {
        currentTheme = "contrast"
      }

      window.localStorage.setItem("theme", currentTheme)
      setThemeState({ theme: currentTheme })
      setTheme(currentTheme) // Mondrian
    }

    setInitialTheme()
  }, [setThemeState])

  const toggleTheme = () => {
    const theme = window.localStorage.getItem("theme")
    let newTheme = ""
    if (!theme || theme === "claro") {
      newTheme = "contrast"
    } else {
      newTheme = "claro"
    }
    window.localStorage.setItem("theme", newTheme)
    setThemeState({ theme: newTheme })
    setTheme(newTheme) // Mondrian

    CroService.postDataLayer({
      event: 'event',
      eventCategory: "planos-claro-res:geral",
      eventAction: "clique:link",
      eventLabel: "contraste"
    })
  }

  return {
    toggleTheme,
    themeState
  }
}

export default useTheme
