import {
  useState,
  forwardRef,
  useImperativeHandle,
  MouseEventHandler
} from "react"
import * as M from "mondrian-react"
import CroService from "../../services/CroService"

interface EcomModalProps {
  header: string
  body: string
  buttonType: string
  buttonLabel: string
  link?: string
  buttonOnClick?: MouseEventHandler<HTMLButtonElement>
}

function EcomModal(props: EcomModalProps, ref) {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const { header, body, buttonType, link, buttonLabel } = props

  useImperativeHandle(ref, () => ({
    handleModalVisibility: handleModalVisibility
  }))

  const ModalHeader = () => {
    return <M.Heading>{header}</M.Heading>
  }

  const ModalBody = () => {
    return (
      <>
        <M.Text>{body}</M.Text>
        {buttonType === "link" && (
          <a
            className="mdn-Button mdn-Button--primary customMargin"
            onClick={() => setIsModalOpen(false)}
            href={link}
          >
            {buttonLabel}
          </a>
        )}

        {buttonType === "button" && (
          <button
            className="mdn-Button mdn-Button--primary customMargin"
            onClick={(e) => handleButtonOnClick(e)}
          >
            {buttonLabel}
          </button>
        )}
      </>
    )
  }

  function handleButtonOnClick(e) {
    setIsModalOpen(false)
    if (props.buttonOnClick) {
      props.buttonOnClick(e)
    }
  }

  function handleModalVisibility() {
    setIsModalOpen(!isModalOpen)

    if (!isModalOpen) {
      CroService.postDataLayer({
        event: 'event',
        eventCategory: "planos-claro-res:modal-acessibilidade-foco",
        eventAction: "modal:abriu",
        eventLabel: "acessibilidade-foco"
      })
    }
  }

  return (
    <M.Modal
      sm
      data={{
        header: <ModalHeader />,
        content: <ModalBody />,
        isOpen: isModalOpen,
        onCloseClick: handleModalVisibility
      }}
    />
  )
}

export default forwardRef(EcomModal)
